.gblog {
  padding: $space-3 0;
  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $space-4 $space-3;
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }
}

.thumb-blog {
  &__cover {
    margin-bottom: $space-1;
    display: block;
    img {
      border-radius: $border-radius;
      max-width: 100%;
      height: auto;
      aspect-ratio: 16 / 11;
      object-fit: cover;
    }
  }

  &__tax {
    font-size: $font-size;
    a {
      color: $color-secondary-dark;
      // text-transform: uppercase;
      text-decoration: none;
      font-weight: $font-weight-bold;
    }
  }

  &__title {
    font-size: $header-2;
    a {
      color: $color-dark;
      text-decoration: none;
      font-weight: $font-weight-medium;
    }
  }

  &__excerpt {
    font-size: $font-size-small;
  }
}

.sblog {
  padding: $space-3 0;
  &__grid {
    display: grid;
    grid-template-columns: 100px 1fr;
    gap: $space-2;
    @media screen and (min-width: 992px) {
      width: 80%;
    }
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      gap: $space-2;
    }
  }

  &__author {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    font-size: $font-size-small;

    text-decoration: none;
    @media screen and (max-width: 767px) {
      font-size: $font-size-smaller;
    }
  }

  &__avatar {
    margin-right: 1rem;
    @media screen and (max-width: 767px) {
      margin-right: 0.5rem;
    }
    .avatar {
      border-radius: 50px;
      border: 1px solid $color-grey-3;
      width: 50px;
      height: auto;
      @media screen and (max-width: 767px) {
        width: 50px;
        height: auto;
      }
    }
  }

  &__nombre {
    font-weight: $font-weight-bold;

    a {
      color: $color-grey-2;
      text-decoration: none;
    }
  }

  &__fecha {
    color: $color-grey-3;
  }

  &__share {
    @media screen and (max-width: 992px) {
      grid-row: 2 / 3;
    }
  }

  &__article {
    display: flex;
    flex-direction: column;
  }
}

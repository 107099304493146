.feat {
  padding: $space-3 0;
  &__content {
    display: flex;
    align-items: flex-start;
    gap: $space-4;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: $space-3;
    align-items: flex-start;
  }

  &__col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__col--1 {
  }

  &__img {
    img {
      aspect-ratio: 16/10;
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: $border-radius;
    }
  }

  &__col--2 {
    width: 100%;
  }

  &__descripcion {
  }
}

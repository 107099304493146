.form-control {
  width: 100%;
  padding: 0.575rem 0.75rem;
  font-size: 1.125rem;
}
.form-label {
  margin-top: 1rem;
  font-size: $font-size-small;
  margin-bottom: 0.2rem;
  font-weight: $font-weight-bold;
}
.wpcf7-list-item {
  // padding: 0.575rem 0;
  margin-left: 0 !important;
}
// .wpcf7-acceptance {
//   font-size: 0.7rem;
//   color: $color-grey-3;
//   color: rgba(0, 0, 0, 0.4);
// }
// span[data-name="gdpr"] {
//
// }

.wpcf7-checkbox {
  display: flex;
  flex-wrap: wrap;
  gap: 5px $font-size;
}

.wpcf7-response-output {
  margin: 2em 0.5em 1em;
  padding: 0.5em 1em;
  border-radius: 8px;
}
.wpcf7-mail-sent-ok {
  border: 2px solid #398f14;
  background-color: #398f14;
  color: $color-background;
}

.wpcf7-mail-sent-ng {
  border: 2px solid #ff0000;
  background-color: #ff0000;
  color: $color-background;
}

.wpcf7-spam-blocked {
  border: 2px solid #ffa500;
  background-color: #ffa500;
}

.wpcf7-acceptance-missig {
  border: 2px solid #ffa500 !important;
  background-color: #ffa500 !important;
}

.ff {
  &__item {
    background-color: $color-grey-6;
    padding: $space-1;
    margin-bottom: $space-1;
    border-radius: 0.7rem;
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
  &__title {
    color: $color-dark;
    font-size: $header-4;
    font-weight: $font-weight-bold;
    @media screen and (max-width: 991px) {
      font-size: $header-3;
      line-height: 1;
    }
  }
}

.gdpr-text {
  font-size: 0.7rem;
  color: $color-grey-3;
  color: rgba(0, 0, 0, 0.4);
  padding-bottom: $space-1;
  display: block;
  a {
    color: $color-grey-3;
  }
}

.wpcf7-acceptance {
  //   font-size: 0.7rem;
  //   color: $color-grey-3;
  //   color: rgba(0, 0, 0, 0.4);
  //   padding: $font-size 0;
  //   display: block;
  a {
    color: $color-grey-3;
  }
}

body:not(.page-template-page-contacto) {
  .grecaptcha-badge {
    display: none;
  }
}

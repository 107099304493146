.documentacion {
  &__list {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    gap: $space-1;
  }

  &__item {
    display: grid;
    grid-template-columns: 100px 1fr 200px;
    align-items: center;
    padding: $space-2;
    background-color: $color-grey-6;
    border-radius: $border-radius;
    @media screen and (max-width: 767px) {
      padding: $space-1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: $font-size;
    }
  }

  &__icono {
    img {
      width: 67px;
      height: auto;
      @media screen and (max-width: 767px) {
        width: 56px;
      }
      @media screen and (max-width: 480px) {
        width: 48px;
      }
    }
  }

  &__titulo {
    color: $color-primary-dark;
    font-size: $header-3;
    font-weight: $font-weight-bold;
    line-height: 1.2;
    margin-bottom: calc($font-size * 0.5);
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-right: 1px solid $color-grey-3;
    @media screen and (max-width: 767px) {
      border: none;
    }
  }

  &__detalles {
    display: flex;
    align-items: center;
    gap: $font-size;
  }

  &__formato,
  &__tamanyo {
    font-size: $font-size;
    color: $color-grey-3;
    span {
      font-weight: $font-weight-bold;
      color: $color-primary;
    }
  }

  &__descargar {
    justify-self: flex-end;
  }
}
